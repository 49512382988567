async function getJsonData(url) {
    const headers = headerFactory();
    headers.set('Content-Type', 'application/json');

    const request = new Request(url, {
        method: 'GET',
        headers: headers
    });
    return await sendJsonRequest(request)
}

async function postJsonData(url, json, method) {
    const headers = headerFactory();
    headers.set('Content-Type', 'application/json');

    const request = new Request(url, {
        method: method || 'POST',
        headers: headers,
        body: json
    });
    return await sendJsonRequest(request)
}

async function postData(url, data, method) {
    const headers = headerFactory();

    const request = new Request(url, {
        method: method || 'POST',
        headers: headers,
        body: data
    });
    return await sendJsonRequest(request)
}

async function sendJsonRequest(request) {
    return fetch(request)
        .then(response => {
            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                return response;
            }
            throw Error(response.statusText);
        })
        .then(response => Promise.all([response, response.json()]))
        .then(([response, json]) => {
            if (response.ok) {
                return json;
            }

            throw Error(json.message || response.statusText);
        }).catch(error => {
            return Promise.reject(error.message);
        })
}


function headerFactory() {
    return new Headers();
}

export {getJsonData, postJsonData, postData}