<template>
  <div class="text-center">
    <b-link :to="{ name: 'findOrder' }">
      <b-button variant="secondary" class="text-uppercase">{{ $t('Create Return') }}</b-button>
    </b-link>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>