<template>
  <b-container>
    <b-row class="h-100">
      <b-col class="my-auto" cols="12" lg="6" offset-lg="3">
        <div class="text-center my-5"><img :src="brand.logo" :alt="brand.name" class="w-100" /></div>
        <b-card>
          <h1>{{ $t('Return Order') }}</h1>
          <hr />
<!--          <b-alert show variant="danger">-->
<!--            {{ $t('Unfortunately, the service is not available at the moment') }}<br>{{ $t('Our team is already working on the solution') }}<br>{{ $t('Please, try again in a few hours') }}-->
<!--          </b-alert>-->
          <b-overlay :show="sendingFindOrder">
            <b-form class="border-1 rounded-4" method="post" :action="getActionUrl.findOrder" @submit.prevent="findOrder">
              <b-form-group :label="$t('Order number') + ':'">
                <b-form-input
                    v-model.trim="form.sourceOrderId"
                    :placeholder="$t('Enter order number')"
                    name="sourceOrderId"
                    :readonly="sendingFindOrder"
                    @input="form.sourceOrderId = form.sourceOrderId.toUpperCase()"
                    required
                    autofocus
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Order Email') + ':'">
                <b-form-input
                    v-model.trim="form.email"
                    name="email"
                    :placeholder="$t('Enter email')"
                    :readonly="sendingFindOrder"
                    required
                ></b-form-input>
              </b-form-group>
              <b-input hidden v-model="form.sourceSlug" name="sourceSlug"></b-input>
              <b-button type="submit" variant="outline-success" :disabled="sendingFindOrder">{{ $t('Submit') }}</b-button>
              <b-alert class="mt-3" v-if="error" show="show" fade="fade" variant="danger">{{ $t(error) }}</b-alert>
            </b-form>
          </b-overlay>
        </b-card>
        <div class="text-center mt-3"><b-link :href="brand.url">{{ brand.url }}</b-link></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {postJsonData} from "@/services/fetchApi";
const blnLogo = require('@/assets/logo_BLN_word.svg');
const ppLogo = require('@/assets/logo_PP_word_hex.svg');
const psLogo = require('@/assets/logo_PS_word_black.svg');
const poLogo = require('@/assets/logo_PO_mod_word.svg');
const soLogo = require('@/assets/logo_SO_white.svg');

export default {
  data() {
    return {
      sendingFindOrder: false,
      error: "",
      form: {
        sourceOrderId: null,
        email: null,
        sourceSlug: ''
      }
    }
  },
  created() {
    if ('src' in this.$route.query) {
      this.form.sourceSlug = this.$route.query.src;
    }
    if ('orderId' in this.$route.query) {
      this.form.sourceOrderId = this.$route.query.orderId;
    }
  },
  methods: {
    findOrder(e) {
      const $this = this;
      $this.sendingFindOrder = true;
      $this.form.email = $this.form.email.trim();
      $this.form.sourceOrderId = $this.form.sourceOrderId.trim();
      $this.error = "";

      postJsonData(e.target.action, JSON.stringify($this.form))
          .then(json => {
            $this.$router.push({ name: 'orderInfo', params: { id: json.id } });
          })
          .catch(error => {
            $this.error = $this.$t(error);
            $this.$store.commit('showToast', {
              message: $this.error,
              title: $this.$t('Error'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.sendingFindOrder = false;
          })

      return false;
    }
  },
  computed: {
    getActionUrl: function () {
      return {
        findOrder: process.env.VUE_APP_BACKEND_SERVER + 'api/find-order'
      }
    },
    brand: function () {
      if ('brand' in this.$route.query) {
        if (this.$route.query.brand === 'Billionaire') {
          return {
            logo: blnLogo,
            name: 'Billionaire',
            url: 'https://billionaire.com'
          }
        } else if (this.$route.query.brand === 'PleinOutlet') {
          return {
            logo: poLogo,
            name: 'PleinOutlet',
            url: 'https://www.pleinoutlet.com/'
          }
        } else if (this.$route.query.brand === 'PleinSport') {
          return {
            logo: psLogo,
            name: 'PleinSport',
            url: 'https://plein.com'
          }
        } else if (this.$route.query.brand === 'SecretOutlet') {
          return {
            logo: soLogo,
            name: 'SecretOutlet',
            url: 'https://secretoutlet.com'
          }
        }
      }

      return {
        logo: ppLogo,
        name: 'PhilippPlein',
        url: 'https://plein.com'
      }
    }
  }
}
</script>