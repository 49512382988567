<template>
  <div id="app">
    <b-navbar type="dark" variant="dark" class="w-100 flex-fill">
      <b-navbar-nav class="w-100">
        <b-nav-item :to="{ name: 'findOrder' }">{{ $t('Create Return') }}</b-nav-item>
        <b-nav-item-dropdown class="ml-auto" :text="$t('languages.' + currentLocale) + ' (' + currentLocale + ')'" right>
          <b-dropdown-item v-for="locale in locales" :key="locale" @click="changeLocale(locale)">{{ $t('languages.' + locale) + ' (' + locale + ')' }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <main class="mt-3">
      <router-view/>
    </main>
  </div>
</template>
<script>
export default {
  created() {
    const getLang = 'lang' in this.$route.query ? this.$route.query.lang : ('locale' in localStorage ? localStorage.locale : null);
    if (getLang && this.locales.includes(getLang)) {
      this.changeLocale(getLang);
    }
  },
  methods: {
    changeLocale(locale) {
      localStorage.locale = locale;
      this.$i18n.locale = locale;
    }
  },
  computed: {
    locales(){
      return Object.keys(this.$i18n.messages)
    },
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    '$i18n.locale'() {
      document.title = this.$t(this.$route.meta.title(this.$route))
    },
    '$route': {
      handler(to) {
        document.title = this.$t(to.meta.title(to))
      },
      immediate: true
    }
  },
}
</script>
<style>
body {
  background-color: #fffefc;
}
#app {
  color: #2c3e50;
}
</style>
