<template>
  <div>
    <b-form-file
        ref="images"
        multiple
        accept="image/*"
        :state="Array.isArray(model) && model.length > 0"
        :placeholder="$t('Choose a file or drop it here...')"
        :required="true"
        @change="filesSelected"
        :disabled="model.length >= 5"
        no-drop
    ></b-form-file>
    <div class="text-right text-muted"><em>{{ $t('Maximum files by maximum size', {size: (maxFileSize / 1000000).toFixed(0), count: maxFilesCount}) }}</em></div>
    <b-alert class="mt-2" variant="danger" :show="!!error">{{ error }}</b-alert>

    <div class="d-flex flex-wrap" style="gap: 1em;">
      <b-card v-for="(file, key) in model" :key="key"
              :img-src="getUrl(file)"
              img-height="100"
              img-width="100"
              class="text-center mt-2"
              body-class="p-1"
              style="flex-basis: 110px;"
      >
        {{ (file.size / 1000000).toFixed(2) }} MB
        <b-button @click="deleteFile(key)" variant="link" class="text-danger">
          <b-icon-trash></b-icon-trash> {{ $t('Delete') }}
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputFilePreview",
  props: ['value', 'required'],
  data() {
    return {
      maxFileSize: 10000000,
      maxFilesCount: 5,
      model: [],
      error: null
    }
  },
  mounted() {
    this.$set(this, 'model', this.value);
  },
  methods: {
    filesSelected(e) {
      e.preventDefault();
      const newFiles = Array.from(e.target.files);
      this.error = null;
      if ((this.model.length + newFiles.length) > this.maxFilesCount) {
        this.error = this.$t('Maximum count files', {count: this.maxFilesCount});
      }
      if (newFiles.filter(file => file.size > this.maxFileSize).length > 0) {
        this.error = this.$t('Maximum a file size', {size: (this.maxFileSize / 1000000).toFixed(0)});
      }
      if (this.error)
        this.model.push();
      else
        this.model.push(...newFiles);
    },
    deleteFile(i) {
      this.error = null;
      this.model.splice(i, 1);
    },
    getUrl(file) {
      return URL.createObjectURL(file)
    }
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      if (!Array.isArray(this.model) || (Array.isArray(this.model) && this.model.length === 0)) {
        this.$refs.images.reset();
      }
      this.$refs.images.files = this.model;
      this.$emit('input', this.model);
    },
  }
}
</script>

<style scoped>

</style>