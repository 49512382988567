import Vue from 'vue'
import Vuex from "vuex";
import toast from "./modules/toast";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        toast: toast
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
});


export default store;