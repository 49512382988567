import OrderFind from "@/components/OrderFind";
import OrderInfo from "@/components/OrderInfo";
import ReturnOrder from "@/components/ReturnOrder";
import Home from "@/components/Home";

const routes = [
    {
        path: "/",
        component: Home,
        name: 'home',
        meta: {
            title: () => 'Home Page'
        }
    },
    {
        path: "/find-order",
        component: OrderFind,
        name: 'findOrder',
        meta: {
            title: () => 'Find Order'
        }
    },
    {
        path: "/find-order/:id",
        name:'orderInfo',
        component: OrderInfo,
        meta: {
            title: () => 'Order Info'
        }
    },
    {
        path: "/return-order/:id",
        name:'returnOrder',
        component: ReturnOrder,
        meta: {
            title: () => 'Return Order'
        }
    },
];

export default routes;