<template>
  <b-container fluid="">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-list-group horizontal="md" class="order-info">
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
        </b-list-group>
        <br class="p-5">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
        <b-alert v-if="error" class="text-center mt-3" show variant="danger">{{ $t(error) }}</b-alert>
      </template>
      <div v-if="!loading">
        <h1>{{ $t('New Return for Order') }} #{{ orderInfo.orderNumber }}</h1>
        <b-overlay :show="sendingCreateRma">
          <b-form method="post" :action="getActionUrl.createRma" @submit.prevent="createRma($event)">
            <b-card>
              <b-row>
                <b-col cols="12" lg="3">
                  <div>{{ $t('Order ID') }}: {{ orderInfo.orderNumber }}</div>
                  <div>{{ $t('Order Date') }}: {{ dateTimeFormat(orderInfo.orderedDate) }}</div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div>{{ $t('Customer Name') }}: {{ orderInfo.billingAddress.fullName }}</div>
                  <div>{{ $t('Email address') }}: {{ orderInfo.email }}</div>
                </b-col>
              </b-row>
            </b-card>
            <b-alert show="" variant="danger" class="text-center mt-3" v-if="!orderInfo.isAvailableForReturnByDate">{{ $t('You can not open a return for this order', { days: '14' }) }}. {{ $t('Please contact us if you have any question') }}.</b-alert>
            <h4 class="mt-5">{{ $t('Step #1: Choose items you would like to return') }}</h4>
            <b-card v-for="(product, productIndex) in listOfProduct.products" :key="product.id" >
              <b-row class="py-3">
                <b-col cols="12" style="max-width: 230px;"><b-img class="w-100 px-3" :src="product.pictureUrl"></b-img></b-col>
                <b-col style="max-width: 400px;">
                  <ul class="list-unstyled">
                    <li><b>{{ product.name }}</b></li>
                    <li>{{ $t('SKU') }}: {{ product.sku }}</li>
                    <li>{{ $t('Color') }}: {{ product.color.name }}</li>
                    <li>{{ $t('Size') }}: {{ product.size }}</li>
                    <li class="my-2">{{ $t('Price') }}: {{ orderInfo.currency}} {{ product.finalPrice }}</li>
                    <li>{{ $t('Ordered') }}: {{ product.quantityOrdered }}</li>
                    <li>{{ $t('Shipped') }}: {{ product.quantityShipped }}</li>
                    <li>{{ $t('Returned') }}: {{ product.quantityReturned }}</li>
                  </ul>
                  <b-form-checkbox
                      switch
                      v-model="model.products[productIndex].wantToReturn"
                      :disabled="!(product.quantityShipped && Number.parseInt(product.quantityShipped) > Number.parseInt(product.quantityReturned)) || product.isInStopList || !orderInfo.isAvailableForReturnByDate"
                      class="text-nowrap"
                  >{{ $t('I want to return this') }}</b-form-checkbox>
                  <b-alert show="" variant="warning" class="d-inline-block" v-if="product.isInStopList">{{ $t('You can not return this item') }}. {{ $t('Please contact us if you have any question') }}.</b-alert>
                </b-col>
                <b-col cols="12" md="3" v-if="model.products[productIndex].wantToReturn">
                    <b-form-group :label="$t('Quantity to return') + '*'">
                      <b-form-select
                          v-model="model.products[productIndex].quantityToReturn"
                          :required="model.products[productIndex].wantToReturn">
                        <b-form-select-option
                            v-for="qty in (Number.parseInt(product.quantityShipped) - Number.parseInt(product.quantityReturned))"
                            :value="qty"
                            :key="qty">{{ qty }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group :label="$t('Reason Of Return') + '*'">
                      <b-form-select
                          v-model="model.products[productIndex].reasonOfReturn"
                          :options="$t('returnReasons')"
                          :required="model.products[productIndex].wantToReturn"
                          @change="resetFiles(productIndex, $event)"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group :label="$t('Upload photo') + '*'" v-if="statusesWithPhotoRequired.includes(model.products[productIndex].reasonOfReturn)">
                      <b-alert variant="warning" show v-if="['RECEIVED_DAMAGED', 'QUALITY_NOT_SATISFYING'].includes(model.products[productIndex].reasonOfReturn)">{{ $t('We kindly ask you to upload the photo with the red security tag attached. If you received the item without the red security tag, please write about that in the comments to your return. Also, the quantity of detailed photos influences directly on the speed of processing your return.') }}</b-alert>
                      <b-alert variant="warning" show v-if="model.products[productIndex].reasonOfReturn === 'DAMAGED_WHEN_WEARING'">{{ $t('We kindly ask you to upload detailed photos of the damage and one photo showing the whole item. If you are claiming a shoe, please upload a photo of its sole too. The quantity of detailed photos influences directly on the speed of processing your return.') }}</b-alert>
                      <b-alert variant="warning" show v-if="model.products[productIndex].reasonOfReturn === 'WRONG_ITEM'">{{ $t('We kindly ask you to upload photos of the item you received and the photos of its labels and documents, which were inside of the box.') }}</b-alert>
                      <InputFilePreview
                        v-model="model.products[productIndex].images"
                        required="required"
                      ></InputFilePreview>
                    </b-form-group>
                    <b-form-group :label="$t('Resolution') + '*'" :required="model.products[productIndex].wantToReturn">
                      <b-form-radio-group
                          v-model="model.products[productIndex].resolution"
                          :options="resolutions"
                          :required="model.products[productIndex].wantToReturn"
                          plain
                          stacked
                      ></b-form-radio-group>
                    </b-form-group>
                    <template v-if="model.products[productIndex].resolution === 'EXCHANGE'">
                      <b-form-group :label="$t('New Size')">
                        <OrderProductSizesForExchange
                            v-model="model.products[productIndex].newSize"
                            :sku="model.products[productIndex].sku"
                            :size="model.products[productIndex].size"
                            :region="orderInfo.region"
                            :country="orderInfo.shipments[0].address.countryCode"
                        ></OrderProductSizesForExchange>
                      </b-form-group>
<!--                      <b-form-group class="text-center text-muted">{{ $t('and/or') }}</b-form-group>-->
<!--                      <b-form-group :label="$t('New Color')">-->
<!--                        <b-input v-model="model.products[productIndex].newColor"></b-input>-->
<!--                      </b-form-group>-->
                    </template>
                </b-col>
                <b-col cols="12" v-if="product.returns.length > 0">
                  <b-row v-for="(rma, indexReturn) in product.returns" :key="rma.id" :index="indexReturn" class="my-3">
                      <b-col cols="12" md="2"><b v-if="indexReturn === 0">{{ $t('Open Returns') }}:</b></b-col>
                      <b-col cols="3">{{ rma.rmaNumber }}</b-col>
                      <b-col cols="4">{{ dateTimeFormat(rma.createdAt) }} <b-badge v-if="rma.rmaNumber === newCreatedRma" variant="success">{{ $t('New') }}</b-badge></b-col>
                      <b-col cols="3">{{ $t(`rma_statuses.${rma.status}`) }}</b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-collapse :visible="returnCanBeCreated" id="collapse-ste-2">
              <h4 class="mt-5">{{ $t('Step #2: Provide us additional information') }}</h4>
              <b-card class="mb-3">
                <b-row>
                  <b-col lg="6">
                    <h3>{{ $t('Return Label') }}</h3>
                    <b-row>
                      <b-col md="2">
                        <b-form-group :label="$t('Salutation')">
                          <b-form-select v-model="model.salutation" :options="$t('salutations')">
                            <template #first>
                              <b-form-select-option :value="null"></b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group :label="$t('First Name') + ' *'">
                          <b-form-input v-model="model.firstName" required></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('Last Name') + ' *'">
                          <b-form-input v-model="model.lastName" required></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6">
                        <b-form-group :label="$t('City') + ' *'">
                          <b-form-input v-model="model.city" required></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col :md="model.country !== 'US' ? 6 : 4">
                        <b-form-group :label="$t('Country') + ' *'">
                          <b-form-input readonly v-model="model.country"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2" v-if="model.country === 'US'">
                        <b-form-group :label="$t('State') + ' *'">
                          <b-form-select required v-model="model.stateCode" :options="$t('usa_states')"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="10">
                        <b-form-group :label="$t('Shipping Address') + ' *'">
                          <b-form-input v-model="model.shippingAddress" required></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group :label="$t('Zip') + ' *'">
                          <b-form-input v-model="model.zipcode" required></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group :label="$t('Phone Number') + ' *'">
                          <b-form-input v-model="model.phone" required></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6">
                    <b-row>
                      <b-col cols="12">
                        <h3>{{ $t('Your comment') }}</h3>
                        <b-form-group>
                          <b-form-textarea
                              v-model="model.comment"
                              :placeholder="$t('If you would like to tell us something')"
                              style="height: 113px;"
                              no-resize
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="model.askBankAccount && selectedEvenOneRefund">
                      <b-col>
                        <h3>{{ $t('Payment Information') }}</h3>
                        <b-row>
                          <b-col md="6">
                            <b-form-group :label="$t('Name and Surname (Account Holder)') + (orderInfo.region !== '04' ? ' *' : '')">
                              <b-form-input v-model="model.paymentFullName" :required="orderInfo.region !== '04'"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="$t('Bank Name') + (orderInfo.region !== '04' ? ' *' : '')">
                              <b-form-input v-model="model.paymentBankName" :required="orderInfo.region !== '04'"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-form-group :label="$t('IBAN') + (orderInfo.region !== '04' ? ' *' : '')">
                              <b-form-input v-model="model.paymentIban" :required="orderInfo.region !== '04'"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="$t('BIC') + (orderInfo.region !== '04' ? ' *' : '')">
                              <b-form-input v-model="model.paymentBic" :required="orderInfo.region !== '04'"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col cols="12" class="text-center">
                    <b-button size="lg" type="submit" variant="success" :disabled="!selectedEvenOneProductToReturn || sendingCreateRma">
                      <b-spinner :class="{'spinner-border mx-3': 1, 'd-none': !sendingCreateRma}"></b-spinner>
                      <span :class="{'d-none': sendingCreateRma}">{{ $t('Create Return') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-form>
        </b-overlay>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>

import {getJsonData, postData} from "@/services/fetchApi";
import {dateTimeFormat} from "@/services/DateTimeFormat";
import OrderProductSizesForExchange from "@/components/OrderProductSizesForExchange";
import InputFilePreview from "@/components/InputFilePreview";

export default {
  components: {
    InputFilePreview,
    OrderProductSizesForExchange
  },

  data() {
    return {
      statusesWithPhotoRequired: ['RECEIVED_DAMAGED', 'QUALITY_NOT_SATISFYING', 'DAMAGED_WHEN_WEARING', 'WRONG_ITEM'],
      loading: true,
      error: null,
      sendingCreateRma: false,
      orderId: null,
      orderInfo: null,
      orderNumber: null,
      newCreatedRma: null,
      model: {
        orderId: '',
        orderNumber: '',
        deliveryService: '',
        salutation: '',
        firstName: '',
        lastName: '',
        shippingAddress: '',
        city: '',
        zipcode: '',
        country: '',
        state: '',
        phone: '',
        comment: '',
        isCod: false,
        isCrypto: false,
        askBankAccount: false,
        paymentFullName: null,
        paymentBankName: null,
        paymentIban: null,
        paymentBic: null,
        products: [],
      },
    }
  },
  methods: {
    createRma(e) {
      const $this = this;
      $this.sendingCreateRma = true;
      let request = JSON.parse(JSON.stringify(this.model));
      request.products = request.products.filter(product => product.wantToReturn);

      const fd = new FormData();
      fd.append('json', JSON.stringify(request));
      this.model.products.filter(product => product.wantToReturn).map((product) => {
        product.images.map((image, j) => {fd.append('files[' + product.id + '][' + j + ']', image);});
      });

      postData(e.target.action, fd)
        .then((json) => {
          $this.newCreatedRma = json.rmaNumber;
          $this.getOrder().then(() => {
            $this.sendingCreateRma = false;
          });
          $this.$store.commit('showToast', {
            message: $this.$t('Return has been created'),
            title: $this.$t('Success'),
            type: 'success'
          });
        })
        .catch(error => {
          $this.$store.commit('showToast', {
            message: $this.$t('Can not create return'),
            title: $this.$t('Error'),
            type: 'danger'
          });
          $this.sendingCreateRma = false;
        })
    },
    initModel: function () {
      const $this = this;
      this.model.orderNumber = this.orderInfo.orderNumber;
      this.model.salutation = this.orderInfo.shipments[0].address.salutation;
      this.model.firstName = this.orderInfo.shipments[0].address.fullName.slice(0, this.orderInfo.shipments[0].address.fullName.indexOf(' '));
      this.model.lastName = this.orderInfo.shipments[0].address.fullName.slice(this.orderInfo.shipments[0].address.fullName.indexOf(' '), this.orderInfo.shipments[0].address.fullName.length);
      this.model.city = this.orderInfo.shipments[0].address.city;
      this.model.country = this.orderInfo.shipments[0].address.countryCode;
      this.model.shippingAddress = this.orderInfo.shipments[0].address.address1;
      if (this.orderInfo.shipments[0].address.address2) {
        this.model.shippingAddress += ' ' + this.orderInfo.shipments[0].address.address2;
      }
      this.model.stateCode = this.orderInfo.shipments[0].address.stateCode;
      this.model.zipcode = this.orderInfo.shipments[0].address.postalCode;
      this.model.phone = this.orderInfo.shipments[0].address.phone;
      this.model.isCod = this.orderInfo.paymentInfo.isCod;
      this.model.isCrypto = this.orderInfo.paymentInfo.isCrypto;
      this.model.askBankAccount = this.orderInfo.paymentInfo.askBankAccount;

      this.orderInfo.shipments.forEach(function (shipment) {
        shipment.products.forEach(function (product, index) {
          $this.$set($this.model.products, index, Object.assign({}, {
                id: product.productId,
                sku: product.sku,
                size: product.size,
                wantToReturn: false,
                quantityToReturn: 0,
                reasonOfReturn: null,
                resolution: null,
                images: [],
                newSize: null
              })
          );
        });
      });
    },
    dateTimeFormat: function (datetime) {
      return dateTimeFormat(datetime);
    },
    getOrder: function () {
      const $this = this;
      return getJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/orderInfo/' + $this.orderId)
          .then(json => {
            $this.orderInfo = json.order;
            $this.$nextTick(function () {
              $this.initModel();
            });
          }).catch(error => {
            $this.error = error;
            $this.$store.commit('showToast', {
              message: $this.$t($this.error),
              title: $this.$t('Error'),
              type: 'danger'
            });
            return Promise.reject();
          });
    },
    resetFiles(i, e) {
      if (!this.statusesWithPhotoRequired.includes(e)) {
        this.model.products[i].images = [];
      }
    }
  },
  mounted() {
    const $this = this;
    $this.getOrder().then(() => {
      $this.loading = false;
    })
    .catch(() => {});
  },
  created() {
    this.orderId = this.$route.params.id
  },
  computed: {
    getActionUrl: function() {
      return {
        createRma: process.env.VUE_APP_BACKEND_SERVER + 'api/returns'
      }
    },
    selectedEvenOneProductToReturn: function() {
      return this.model.products.filter(product => product.wantToReturn).length > 0;
    },
    selectedEvenOneRefund: function() {
      return this.model.products.filter(product => product.resolution === 'REFUND').length > 0;
    },
    listOfProduct() {
      return this.orderInfo.shipments.length > 0 ? this.orderInfo.shipments[0] : null;
    },
    returnCanBeCreated() {
      return this.selectedEvenOneProductToReturn
    },
    resolutions() {
        return Object.keys(this.$t('resolutions'))
            .filter(resolution => resolution !== 'EXCHANGE')
            .reduce((obj, resolution) => ({ ...obj, [resolution]: this.$t('resolutions')[resolution] }), {});
    }
  }
}
</script>