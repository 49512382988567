const toast = {
    state: {
        toasts: []
    },
    mutations: {
        showToast(state, toast) {
            state.toasts.push(toast);
        }
    },
    actions: {
        showToast(context, toast) {
            context.commit('showToast', toast);
        }
    }
}

export default toast;