import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import App from "./components/App"

import { BootstrapVue, FormPlugin, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(FormPlugin)
Vue.use(BootstrapVueIcons)

import store from "./store/store";
import i18n from "@/i18n";

import router from "./router/router";

const vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    beforeCreate() {
        store.subscribe((mutation) => {
            if (mutation.type !== 'showToast') {
                return;
            }

            let message = mutation.payload.message;

            this.$bvToast.toast(message, {
                title: mutation.payload.title,
                variant: mutation.payload.type,
                autoHideDelay: 5000,
                appendToast: true,
                toaster: 'b-toaster-bottom-right'
            });
        });
    }
}).$mount('#app')